import Vue from 'vue'
import Vuex from 'vuex'

// Modules
import app from './app'
import appConfig from './app-config'
import verticalMenu from './vertical-menu'
import couponType from './coupon-type'
import couponCatalog from './coupon-catalog'
import coupon from './coupon'
import group from './group'
import user from './user'

Vue.use(Vuex)

export default new Vuex.Store({
  modules: {
    app,
    appConfig,
    verticalMenu,
    couponType,
    couponCatalog,
    coupon,
    group,
    user,
  },
  strict: process.env.DEV,
})
