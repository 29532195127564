import { v4 as uuidv4 } from 'uuid'

import CreateCouponTypeService from '@/modules/coupon/coupon_type/application/create_coupon_type_service'
import SearchCouponTypeService from '@/modules/coupon/coupon_type/application/search_coupon_type_service'
import GetCouponTypeService from '@/modules/coupon/coupon_type/application/get_coupon_type_service'
import UpdateCouponTypeService from '@/modules/coupon/coupon_type/application/update_coupon_type_service'
import DeleteCouponTypeService from '@/modules/coupon/coupon_type/application/delete_coupon_type_service'

import { datetime } from '@/mixins/datetime'

function initializeCouponType() {
  return {
    id: uuidv4(),
    name: '',
    description: '',
    created_at: '',
    updated_at: '',
  }
}

export default {
  namespaced: true,
  state: {
    isLoading: false,
    errorMessage: '',
    couponTypes: [],
    couponType: initializeCouponType(),
  },
  getters: {},
  mutations: {
    setIsLoading(state, payload) {
      state.isLoading = payload.isLoading
    },
    setCouponTypes(state, payload) {
      state.couponTypes = payload.couponTypes
    },
    setErrorMessage(state, payload) {
      state.errorMessage = payload.errorMessage
    },
    setCouponType(state, payload) {
      state.couponType = payload
    },
    addCouponType(state, payload) {
      state.couponTypes.push(payload)
    },
    setCouponTypeById(state, payload) {
      const index = state.couponTypes.findIndex(c => c.id === payload.id)
      if (index !== -1) {
        state.couponTypes[index] = payload
        state.couponTypes = [...state.couponTypes]
      }
    },
    removeCouponTypeById(state, payload) {
      const index = state.couponTypes.findIndex(c => c.id === payload.id)
      if (index !== -1) {
        state.couponTypes.splice(index, 1)
      }
    },
    restoreCouponType(state) {
      state.couponType = initializeCouponType()
    },
  },
  actions: {
    async searchCouponType({ commit }) {
      commit('setIsLoading', { isLoading: true })

      try {
        const service = new SearchCouponTypeService()
        const response = await service.search()
        if (response && response.success) {
          commit('setCouponTypes', { couponTypes: response.data })
          commit('setErrorMessage', { errorMessage: '' })
        }

        if (response && response.success === false) {
          commit('setErrorMessage', { errorMessage: response.message })
        }
      } catch (error) {
        commit('setErrorMessage', { errorMessage: error.message })
      } finally {
        commit('setIsLoading', { isLoading: false })
      }
    },

    async createCouponType({ commit }, payload) {
      commit('setIsLoading', { isLoading: true })

      try {
        const data = { ...payload }
        data.created_at = datetime.methods.getCurrentTimeOnIsoFormat()
        data.updated_at = data.created_at
        const service = new CreateCouponTypeService()
        const response = await service.create(data)
        if (response && response.success) {
          commit('addCouponType', data)
          commit('setErrorMessage', { errorMessage: '' })
        }

        if (response && response.success === false) {
          commit('setErrorMessage', { errorMessage: response.message })
        }
      } catch (error) {
        commit('setErrorMessage', { errorMessage: error.message })
      } finally {
        commit('setIsLoading', { isLoading: false })
      }
    },

    async getCouponType({ commit }, payload) {
      commit('setIsLoading', { isLoading: true })

      try {
        const service = new GetCouponTypeService()
        const response = await service.get(payload.id)
        if (response && response.success) {
          commit('setCouponType', response.data)
          commit('setErrorMessage', { errorMessage: '' })
        }

        if (response && response.success === false) {
          commit('setErrorMessage', { errorMessage: response.message })
        }
      } catch (error) {
        commit('setErrorMessage', { errorMessage: error.message })
      } finally {
        commit('setIsLoading', { isLoading: false })
      }
    },

    async updateCouponType({ commit }, payload) {
      commit('setIsLoading', { isLoading: true })

      try {
        const data = { ...payload }
        data.updated_at = datetime.methods.getCurrentTimeOnIsoFormat()
        const service = new UpdateCouponTypeService()
        await service.update(payload.id, payload)
        commit('setCouponTypeById', data)
        commit('setErrorMessage', { errorMessage: '' })
      } catch (error) {
        commit('setErrorMessage', { errorMessage: error.message })
      } finally {
        commit('setIsLoading', false)
      }
    },

    async deleteCouponType({ commit }, payload) {
      commit('setIsLoading', { isLoading: true })

      try {
        const service = new DeleteCouponTypeService()
        await service.delete(payload.id)
        commit('removeCouponTypeById', payload)
        commit('setErrorMessage', { errorMessage: '' })
      } catch (error) {
        commit('setErrorMessage', { errorMessage: error.message })
      } finally {
        commit('setIsLoading', { isLoading: false })
      }
    },

    restoreCouponType({ commit }) {
      commit('restoreCouponType')
    },
  },
}
