import httpClient from '@/mixins/http-client'
import GroupRepository from '@/modules/users/group/infrastructure/persistence/api/axios/group_repository'

export default class SearchGroupService {
  private repository: GroupRepository | null

  constructor(_repository: GroupRepository | null = null) {
    this.repository = _repository || new GroupRepository(httpClient, '/groups/')
  }

  search() {
    return this.repository?.search()
  }
}
