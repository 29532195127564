import httpClient from '@/mixins/http-client'
import CouponTypeRepository from '@/modules/coupon/coupon_type/infrastructure/persistence/api/axios/coupon_type_repository'

export default class UpdateCouponTypeService {
  private repository: CouponTypeRepository | null

  constructor(_repository: CouponTypeRepository | null = null) {
    this.repository = _repository || new CouponTypeRepository(httpClient, 'coupon-types/')
  }

  update(id:string, payload: object) {
    return this.repository?.update(id, payload)
  }
}
