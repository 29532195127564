import httpClient from '@/mixins/http-client'
import CouponCatalogRepository from '@/modules/coupon/coupon_catalog/infrastructure/persistence/api/axios/coupon_catalog_repository'

export default class CreateCouponCatalogService {
  private repository: CouponCatalogRepository | null

  constructor(_repository: CouponCatalogRepository | null = null) {
    this.repository = _repository || new CouponCatalogRepository(httpClient, 'coupon-catalogs/')
  }

  create(payload: object) {
    return this.repository?.create(payload)
  }
}
