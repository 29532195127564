import httpClient from '@/mixins/http-client'
import UserRepository from '@/modules/users/user/infrastructure/persistence/api/axios/user_repository'

export default class GetUserService {
  private repository: UserRepository | null

  constructor(_repository: UserRepository | null = null) {
    this.repository = _repository || new UserRepository(httpClient, '/users/')
  }

  get(id: string) {
    return this.repository?.get(id)
  }
}
