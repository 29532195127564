import httpClient from '@/mixins/http-client'
import UserRepository from '@/modules/users/user/infrastructure/persistence/api/axios/user_repository'

export default class UpdateUserService {
  private repository: UserRepository | null

  constructor(_repository: UserRepository | null = null) {
    this.repository = _repository || new UserRepository(httpClient, '/users/')
  }

  update(id:string, payload: object) {
    return this.repository?.update(id, payload)
  }
}
