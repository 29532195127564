import Vue from 'vue'
import VueRouter from 'vue-router'
import coreRoutes from '@/router/modules/core'
import catalogRoutes from '@/router/modules/catalogs'

Vue.use(VueRouter)

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  scrollBehavior() {
    return { x: 0, y: 0 }
  },
  routes: [
    ...coreRoutes,
    ...catalogRoutes,
    {
      path: '*',
      redirect: 'error-404',
    },
  ],
})

export default router
