import httpClient from '@/mixins/http-client'
import CouponRepository from '@/modules/coupon/coupon/infrastructure/persistence/api/axios/coupon_repository'

export default class CreateCouponService {
  private repository: CouponRepository | null

  constructor(_repository: CouponRepository | null = null) {
    this.repository = _repository || new CouponRepository(httpClient, 'coupons/')
  }

  create(payload: object) {
    return this.repository?.create(payload)
  }
}
