import axios from 'axios'
import { createToastInterface } from 'vue-toastification'

const defaultOptions = {
  baseURL: `${process.env.VUE_APP_BASEURL}/api/v1`,
  timeout: process.env.VUE_APP_TIMEOUT,
  headers: {},
}

const httpClient = axios.create(defaultOptions)

httpClient.interceptors.response.use(response => response.data, error => {
  if (error.response && error.response.status === 404) {
    window.location.href = '/error-404'
  }

  if (error.response && error.response.status === 403) {
    window.location.href = '/error-403'
  }

  if (error.response && error.response.status === 500) {
    const pluginOptions = {
      timeout: 4000,
    }
    const toast = createToastInterface(pluginOptions)
    if (error.response.data && error.response.data.message) {
      toast.error(error.response.data.message)
    }
    if (!error.response.data.message) {
      toast.error('Ocurrio un error al procesar la petición')
    }
  }

  return Promise.reject(error)
})

export default httpClient
