import { v4 as uuidv4 } from 'uuid'

import CreateUserService from '@/modules/users/user/application/create_user_service'
import SearchUserService from '@/modules/users/user/application/search_user_service'
import GetUserService from '@/modules/users/user/application/get_user_service'
import UpdateUserService from '@/modules/users/user/application/update_user_service'
import DeleteUserService from '@/modules/users/user/application/delete_user_service'
import CreateUserGroupService from '@/modules/users/user_group/application/create_user_grup_service'

import { datetime } from '@/mixins/datetime'

function initializeUser() {
  return {
    id: uuidv4(),
    username: '',
    password: '',
    confirm_password: '',
    is_active: true,
    created_at: '',
    updated_at: '',
    group_id: null,
  }
}

export default {
  namespaced: true,
  state: {
    isLoading: false,
    errorMessage: '',
    users: [],
    user: initializeUser(),
  },
  getters: {},
  mutations: {
    setIsLoading(state, payload) {
      state.isLoading = payload.isLoading
    },
    setUsers(state, payload) {
      state.users = payload.users
    },
    setErrorMessage(state, payload) {
      state.errorMessage = payload.errorMessage
    },
    setUser(state, payload) {
      state.user = payload
    },
    addUser(state, payload) {
      state.users.push(payload)
    },
    setUserById(state, payload) {
      const index = state.users.findIndex(c => c.id === payload.id)
      if (index !== -1) {
        state.users[index] = payload
        state.users = [...state.users]
      }
    },
    removeUserById(state, payload) {
      const index = state.users.findIndex(c => c.id === payload.id)
      if (index !== -1) {
        state.users.splice(index, 1)
      }
    },
    restoreUser(state) {
      state.user = initializeUser()
    },
  },
  actions: {
    async searchUser({ commit }) {
      commit('setIsLoading', { isLoading: true })

      try {
        const service = new SearchUserService(null)
        const response = await service.search()
        if (response && response.success) {
          commit('setUsers', { users: response.data })
          commit('setErrorMessage', { errorMessage: '' })
        }

        if (response && response.success === false) {
          commit('setErrorMessage', { errorMessage: response.message })
        }
      } catch (error) {
        commit('setErrorMessage', { errorMessage: error.message })
      } finally {
        commit('setIsLoading', { isLoading: false })
      }
    },

    async createUser({ commit }, payload) {
      commit('setIsLoading', { isLoading: true })

      try {
        const data = { ...payload }
        data.created_at = datetime.methods.getCurrentTimeOnIsoFormat()
        data.updated_at = data.created_at
        const service = new CreateUserService(null)
        const response = await service.create(data)
        const serviceCreateUserGroup = new CreateUserGroupService(null)
        if (response && response.success) {
          const userGroup = {
            id: uuidv4(),
            user_id: payload.id,
            group_id: payload.group_id,
          }
          const responseCreateGroup = await serviceCreateUserGroup.create(userGroup)
          if (responseCreateGroup && responseCreateGroup.success) {
            commit('addUser', data)
            commit('setErrorMessage', { errorMessage: '' })
          }
        }

        if (response && response.success === false) {
          commit('setErrorMessage', { errorMessage: response.message })
        }
      } catch (error) {
        commit('setErrorMessage', { errorMessage: error.message })
      } finally {
        commit('setIsLoading', { isLoading: false })
      }
    },

    async getUser({ commit }, payload) {
      commit('setIsLoading', { isLoading: true })

      try {
        const service = new GetUserService(null)
        await service.get(payload.id)
        commit('setErrorMessage', { errorMessage: '' })
      } catch (error) {
        commit('setErrorMessage', { errorMessage: error.message })
      } finally {
        commit('setIsLoading', { isLoading: false })
      }
    },

    async updateUser({ commit }, payload) {
      commit('setIsLoading', { isLoading: true })

      try {
        const data = { ...payload }
        data.updated_at = datetime.methods.getCurrentTimeOnIsoFormat()
        const service = new UpdateUserService(null)
        await service.update(payload.id, data)
        commit('setUserById', data)
        commit('setErrorMessage', { errorMessage: '' })
      } catch (error) {
        commit('setErrorMessage', { errorMessage: error.message })
      } finally {
        commit('setIsLoading', false)
      }
    },

    async deleteUser({ commit }, payload) {
      commit('setIsLoading', { isLoading: true })

      try {
        const service = new DeleteUserService(null)
        const response = await service.delete(payload.id)
        if (response && response.success) {
          commit('removeUserById', payload)
          commit('setErrorMessage', { errorMessage: '' })
        }

        if (response && response.success === false) {
          commit('setErrorMessage', { errorMessage: response.message })
        }
      } catch (error) {
        commit('setErrorMessage', { errorMessage: error.message })
      } finally {
        commit('setIsLoading', { isLoading: false })
      }
    },

    restoreUser({ commit }) {
      commit('restoreUser')
    },
  },
}
