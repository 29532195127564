import { v4 as uuidv4 } from 'uuid'

import CreateGroupService from '@/modules/users/group/application/create_group_service'
import SearchGroupService from '@/modules/users/group/application/search_group_service'
import GetGroupService from '@/modules/users/group/application/get_group_service'
import UpdateGroupService from '@/modules/users/group/application/update_group_service'
import DeleteGroupService from '@/modules/users/group/application/delete_group_service'

import { datetime } from '@/mixins/datetime'

function initializeGroup() {
  return {
    id: uuidv4(),
    name: '',
    description: '',
    created_at: '',
    updated_at: '',
  }
}

export default {
  namespaced: true,
  state: {
    isLoading: false,
    errorMessage: '',
    groups: [],
    group: initializeGroup(),
  },
  getters: {},
  mutations: {
    setIsLoading(state, payload) {
      state.isLoading = payload.isLoading
    },
    setGroups(state, payload) {
      state.groups = payload.groups
    },
    setErrorMessage(state, payload) {
      state.errorMessage = payload.errorMessage
    },
    setGroup(state, payload) {
      state.group = payload
    },
    addGroup(state, payload) {
      state.groups.push(payload)
    },
    setGroupById(state, payload) {
      const index = state.groups.findIndex(c => c.id === payload.id)
      if (index !== -1) {
        state.groups[index] = payload
        state.groups = [...state.groups]
      }
    },
    removeGroupById(state, payload) {
      const index = state.groups.findIndex(c => c.id === payload.id)
      if (index !== -1) {
        state.groups.splice(1, 1)
      }
    },
    restoreGroup(state) {
      state.group = initializeGroup()
    },
  },
  actions: {
    async searchGroup({ commit }) {
      commit('setIsLoading', { isLoading: true })

      try {
        const service = new SearchGroupService(null)
        const response = await service.search()
        if (response && response.success) {
          commit('setGroups', { groups: response.data })
          commit('setErrorMessage', { errorMessage: '' })
        }

        if (response && response.success === false) {
          commit('setErrorMessage', { errorMessage: response.message })
        }
      } catch (error) {
        commit('setErrorMessage', { errorMessage: error.message })
      } finally {
        commit('setIsLoading', { isLoading: false })
      }
    },

    async createGroup({ commit }, payload) {
      commit('setIsLoading', { isLoading: true })

      try {
        const data = { ...payload }
        data.created_at = datetime.methods.getCurrentTimeOnIsoFormat()
        data.updated_at = data.created_at
        const service = new CreateGroupService(null)
        const response = await service.create(data)
        if (response && response.success) {
          commit('addGroup', data)
          commit('setErrorMessage', { errorMessage: '' })
        }

        if (response && response.success === false) {
          commit('setErrorMessage', { errorMessage: response.message })
        }
      } catch (error) {
        commit('setErrorMessage', { errorMessage: error.message })
      } finally {
        commit('setIsLoading', { isLoading: false })
      }
    },

    async getGroup({ commit }, payload) {
      commit('setIsLoading', { isLoading: true })

      try {
        const service = new GetGroupService(null)
        await service.get(payload.id)
        commit('setErrorMessage', { errorMessage: '' })
      } catch (error) {
        commit('setErrorMessage', { errorMessage: error.message })
      } finally {
        commit('setIsLoading', { isLoading: false })
      }
    },

    async updateGroup({ commit }, payload) {
      commit('setIsLoading', { isLoading: true })

      try {
        const data = { ...payload }
        data.updated_at = datetime.methods.getCurrentTimeOnIsoFormat()
        const service = new UpdateGroupService(null)
        await service.update(payload.id, data)
        commit('setGroupById', data)
        commit('setErrorMessage', { errorMessage: '' })
      } catch (error) {
        commit('setErrorMessage', { errorMessage: error.message })
      } finally {
        commit('setIsLoading', false)
      }
    },

    async deleteGroup({ commit }, payload) {
      commit('setIsLoading', { isLoading: true })

      try {
        const service = new DeleteGroupService(null)
        const response = await service.delete(payload.id)
        if (response && response.success) {
          commit('removeGroupById', payload)
          commit('setErrorMessage', { errorMessage: '' })
        }

        if (response && response.success === false) {
          commit('setErrorMessage', { errorMessage: response.message })
        }
      } catch (error) {
        commit('setErrorMessage', { errorMessage: error.message })
      } finally {
        commit('setIsLoading', { isLoading: false })
      }
    },

    restoreGroup({ commit }) {
      commit('restoreGroup')
    },
  },
}
