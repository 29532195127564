import { v4 as uuidv4 } from 'uuid'

import CreateCouponService from '@/modules/coupon/coupon/application/create_coupon_service'
import SearchCouponService from '@/modules/coupon/coupon/application/search_coupon_service'
import GetCouponService from '@/modules/coupon/coupon/application/get_coupon_service'
import UpdateCouponService from '@/modules/coupon/coupon/application/update_coupon_service'
import DeleteCouponService from '@/modules/coupon/coupon/application/delete_coupon_service'

import { datetime } from '@/mixins/datetime'

function initializeCoupon() {
  return {
    id: uuidv4(),
    coupon_catalog_id: '',
    status: '',
    code: '',
    created_at: '',
    updated_at: '',
  }
}

export default {
  namespaced: true,
  state: {
    isLoading: false,
    errorMessage: '',
    coupons: [],
    coupon: initializeCoupon(),
  },
  getters: {},
  mutations: {
    setIsLoading(state, payload) {
      state.isLoading = payload.isLoading
    },
    setCoupons(state, payload) {
      state.coupons = payload.coupons
    },
    setErrorMessage(state, payload) {
      state.errorMessage = payload.errorMessage
    },
    setCoupon(state, payload) {
      state.coupon = payload
    },
    addCoupon(state, payload) {
      state.coupons.push(payload)
    },
    setCouponById(state, payload) {
      const index = state.coupons.findIndex(c => c.id === payload.id)
      if (index !== -1) {
        state.coupons[index] = payload
        state.coupons = [...state.coupons]
      }
    },
    removeCouponById(state, payload) {
      const index = state.coupons.findIndex(c => c.id === payload.id)
      if (index !== -1) {
        state.coupons.splice(index, 1)
      }
    },
    restoreCoupon(state) {
      state.coupon = initializeCoupon()
    },
  },
  actions: {
    async searchCoupon({ commit }) {
      commit('setIsLoading', { isLoading: true })

      try {
        const service = new SearchCouponService()
        const response = await service.search()
        if (response && response.success) {
          commit('setCoupons', { coupons: response.data })
          commit('setErrorMessage', { errorMessage: '' })
        }

        if (response && response.success === false) {
          commit('setErrorMessage', { errorMessage: response.message })
        }
      } catch (error) {
        commit('setErrorMessage', { errorMessage: error.message })
      } finally {
        commit('setIsLoading', { isLoading: false })
      }
    },

    async createCoupon({ commit }, payload) {
      commit('setIsLoading', { isLoading: true })

      try {
        const data = { ...payload }
        data.created_at = datetime.methods.getCurrentTimeOnIsoFormat()
        data.updated_at = data.created_at
        const service = new CreateCouponService()
        const response = await service.create(data)
        if (response && response.success) {
          commit('addCoupon', data)
          commit('setErrorMessage', { errorMessage: '' })
        }

        if (response && response.success === false) {
          commit('setErrorMessage', { errorMessage: response.message })
        }
      } catch (error) {
        commit('setErrorMessage', { errorMessage: error.message })
      } finally {
        commit('setIsLoading', { isLoading: false })
      }
    },

    async getCoupon({ commit }, payload) {
      commit('setIsLoading', { isLoading: true })

      try {
        const service = new GetCouponService()
        const response = await service.get(payload.id)
        if (response && response.success) {
          commit('setCoupon', response.data)
          commit('setErrorMessage', { errorMessage: '' })
        }

        if (response && response.success === false) {
          commit('setErrorMessage', { errorMessage: response.message })
        }
      } catch (error) {
        commit('setErrorMessage', { errorMessage: error.message })
      } finally {
        commit('setIsLoading', { isLoading: false })
      }
    },

    async updateCoupon({ commit }, payload) {
      commit('setIsLoading', { isLoading: true })

      try {
        const data = { ...payload }
        data.updated_at = datetime.methods.getCurrentTimeOnIsoFormat()
        const service = new UpdateCouponService()
        await service.update(payload.id, payload)
        commit('setCouponById', data)
        commit('setErrorMessage', { errorMessage: '' })
      } catch (error) {
        commit('setErrorMessage', { errorMessage: error.message })
      } finally {
        commit('setIsLoading', false)
      }
    },

    async deleteCoupon({ commit }, payload) {
      commit('setIsLoading', { isLoading: true })

      try {
        const service = new DeleteCouponService()
        await service.delete(payload.id)
        commit('removeCouponById', payload)
        commit('setErrorMessage', { errorMessage: '' })
      } catch (error) {
        commit('setErrorMessage', { errorMessage: error.message })
      } finally {
        commit('setIsLoading', { isLoading: false })
      }
    },

    restoreCoupon({ commit }) {
      commit('restoreCoupon')
    },
  },
}
