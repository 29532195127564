import httpClient from '@/mixins/http-client'
import CouponRepository from '@/modules/coupon/coupon/infrastructure/persistence/api/axios/coupon_repository'

export default class SearchCouponService {
  private repository: CouponRepository | null

  constructor(_repository: CouponRepository | null = null) {
    this.repository = _repository || new CouponRepository(httpClient, 'coupons/')
  }

  search() {
    return this.repository?.search()
  }
}
