import httpClient from '@/mixins/http-client'
import GroupRepository from '@/modules/users/group/infrastructure/persistence/api/axios/group_repository'

export default class DeleteGroupService {
  private repository: GroupRepository | null

  constructor(_repository: GroupRepository | null = null) {
    this.repository = _repository || new GroupRepository(httpClient, '/groups/')
  }

  delete(id: string) {
    return this.repository?.delete(id)
  }
}
