export const datetime = {
  methods: {
    getCurrentTimeOnIsoFormat() {
      const now = new Date()
      return `${now.toISOString().slice(0, -1)}+00:00`
    },
  },
}

export default datetime
