import axios from 'axios'
import { Repository as AbstractRepository } from '@/modules/shared/domain/api/repository'

export default class CouponRepository implements AbstractRepository {
  private httpClient: any

  private url: string

  constructor(_httpClient: any, _url: string) {
    this.httpClient = _httpClient || axios
    this.url = _url || ''
  }

  search(): Promise<object> {
    return this.httpClient.get(this.url)
  }

  get(id: string): Promise<object> {
    return this.httpClient.get(`${this.url}${id}`)
  }

  create(payload: object): Promise<object> {
    return this.httpClient.post(this.url, payload)
  }

  update(id: string, payload: object): Promise<object> {
    return this.httpClient.put(`${this.url}${id}`, payload)
  }

  delete(id: string): Promise<object> {
    return this.httpClient.delete(`${this.url}${id}`)
  }
}
