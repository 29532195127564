import httpClient from '@/mixins/http-client'
import UserGroupRepository from '@/modules/users/user_group/infrastructure/persistence/api/axios/user_group_repository'

export default class CreateUserGroupService {
  private repository: UserGroupRepository | null

  constructor(_repository: UserGroupRepository | null = null) {
    this.repository = _repository || new UserGroupRepository(httpClient, '/user-groups/')
  }

  create(payload: object) {
    return this.repository?.create(payload)
  }
}
