export default [
  {
    path: '/coupon-type',
    name: 'coupon-type',
    component: () => import('@/views/catalogs/CouponType'),
    meta: {
      pageTitle: 'Coupon Type',
      breadcrumb: [
        {
          text: 'Coupon Type',
          active: true,
        },
      ],
    },
  },
  {
    path: '/coupon-catalog',
    name: 'coupon-catalog',
    component: () => import('@/views/catalogs/CouponCatalog.vue'),
    meta: {
      pageTitle: 'Coupon Catalog',
      breadcrumb: [
        {
          text: 'Coupon Catalog',
          active: true,
        },
      ],
    },
  },
  {
    path: '/coupon',
    name: 'coupon',
    component: () => import('@/views/catalogs/Coupon.vue'),
    meta: {
      pageTitle: 'Coupon',
      breadcrumb: [
        {
          text: 'Coupon',
          active: true,
        },
      ],
    },
  },
  {
    path: '/group',
    name: 'group',
    component: () => import('@/views/catalogs/Group.vue'),
    meta: {
      pageTitle: 'Group',
      breadcrumb: [
        {
          text: 'Group',
          active: true,
        },
      ],
    },
  },
  {
    path: '/user',
    name: 'user',
    component: () => import('@/views/catalogs/User.vue'),
    meta: {
      pageTitle: 'User',
      breadcrumb: [
        {
          text: 'User',
          active: true,
        },
      ],
    },
  },
]
