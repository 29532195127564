import { v4 as uuidv4 } from 'uuid'

import CreateCouponCatalogService from '@/modules/coupon/coupon_catalog/application/create_coupon_catalog_service'
import SearchCouponCatalogService from '@/modules/coupon/coupon_catalog/application/search_coupon_catalog_service'
import GetCouponTyṕeService from '@/modules/coupon/coupon_catalog/application/get_coupon_catalog_service'
import UpdateCouponCatalogService from '@/modules/coupon/coupon_catalog/application/update_coupon_catalog_service'
import DeleteCouponCatalogService from '@/modules/coupon/coupon_catalog/application/delete_coupon_catalog_service'

import { datetime } from '@/mixins/datetime'

function initializeCouponCatalog() {
  return {
    id: uuidv4(),
    coupon_type_id: '',
    name: '',
    description: '',
    config: {},
    created_at: '',
    updated_at: '',
  }
}

export default {
  namespaced: true,
  state: {
    isLoading: false,
    errorMessage: '',
    couponCatalogs: [],
    couponCatalog: initializeCouponCatalog(),
  },
  getters: {},
  mutations: {
    setIsLoading(state, payload) {
      state.isLoading = payload.isLoading
    },
    setCouponCatalogs(state, payload) {
      state.couponCatalogs = payload.couponCatalogs
    },
    setErrorMessage(state, payload) {
      state.errorMessage = payload.errorMessage
    },
    setCouponCatalog(state, payload) {
      state.couponCatalog = payload
    },
    addCouponCatalog(state, payload) {
      state.couponCatalogs.push(payload)
    },
    setCouponCatalogById(state, payload) {
      const index = state.couponCatalogs.findIndex(c => c.id === payload.id)
      if (index !== -1) {
        state.couponCatalogs[index] = payload
        state.couponCatalogs = [...state.couponCatalogs]
      }
    },
    removeCouponCatalogById(state, payload) {
      const index = state.couponCatalogs.findIndex(c => c.id === payload.id)
      if (index !== -1) {
        state.couponCatalogs.splice(index, 1)
      }
    },
    restoreCouponCatalog(state) {
      state.couponCatalog = initializeCouponCatalog()
    },
  },
  actions: {
    async searchCouponCatalog({ commit }) {
      commit('setIsLoading', { isLoading: true })

      try {
        const service = new SearchCouponCatalogService()
        const response = await service.search()
        if (response && response.success) {
          commit('setCouponCatalogs', { couponCatalogs: response.data })
          commit('setErrorMessage', { errorMessage: '' })
        }

        if (response && response.success === false) {
          commit('setErrorMessage', { errorMessage: response.message })
        }
      } catch (error) {
        commit('setErrorMessage', { errorMessage: error.message })
      } finally {
        commit('setIsLoading', { isLoading: false })
      }
    },

    async createCouponCatalog({ commit }, payload) {
      commit('setIsLoading', { isLoading: true })

      try {
        const data = { ...payload }
        data.created_at = datetime.methods.getCurrentTimeOnIsoFormat()
        data.updated_at = data.created_at
        const service = new CreateCouponCatalogService()
        const response = await service.create(data)
        if (response && response.success) {
          commit('addCouponCatalog', data)
          commit('setErrorMessage', { errorMessage: '' })
        }

        if (response && response.success === false) {
          commit('setErrorMessage', { errorMessage: response.message })
        }
      } catch (error) {
        commit('setErrorMessage', { errorMessage: error.message })
      } finally {
        commit('setIsLoading', { isLoading: false })
      }
    },

    async getCouponCatalog({ commit }, payload) {
      commit('setIsLoading', { isLoading: true })

      try {
        const service = new GetCouponTyṕeService()
        const response = await service.get(payload.id)
        if (response && response.success) {
          commit('setCouponCatalog', response.data)
          commit('setErrorMessage', { errorMessage: '' })
        }

        if (response && response.success === false) {
          commit('setErrorMessage', { errorMessage: response.message })
        }
      } catch (error) {
        commit('setErrorMessage', { errorMessage: error.message })
      } finally {
        commit('setIsLoading', { isLoading: false })
      }
    },

    async updateCouponCatalog({ commit }, payload) {
      commit('setIsLoading', { isLoading: true })

      try {
        const data = { ...payload }
        data.updated_at = datetime.methods.getCurrentTimeOnIsoFormat()
        const service = new UpdateCouponCatalogService()
        await service.update(payload.id, payload)
        commit('setCouponCatalogById', data)
        commit('setErrorMessage', { errorMessage: '' })
      } catch (error) {
        commit('setErrorMessage', { errorMessage: error.message })
      } finally {
        commit('setIsLoading', false)
      }
    },

    async deleteCouponCatalog({ commit }, payload) {
      commit('setIsLoading', { isLoading: true })

      try {
        const service = new DeleteCouponCatalogService()
        await service.delete(payload.id)
        commit('removeCouponCatalogById', payload)
        commit('setErrorMessage', { errorMessage: '' })
      } catch (error) {
        commit('setErrorMessage', { errorMessage: error.message })
      } finally {
        commit('setIsLoading', { isLoading: false })
      }
    },

    restoreCouponCatalog({ commit }) {
      commit('restoreCouponCatalog')
    },
  },
}
